import { ApolloClient, InMemoryCache, createHttpLink, } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization:  `Bearer TEST` ,
    },
  };
});

const httpLink = createHttpLink({
  uri: `${process.env.READ_ONLY_GRAPHQL}`,
});

// Create the error link to handle 401 errors
const errorLink = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    // Handle the 401 error here
    // For example, log the error or redirect to a login screen
    console.log("Authorization error")
  } else if (networkError && networkError.statusCode == 500) {
    console.log("Server error", networkError.message)
  } else {
    console.log("Error", networkError)
  }
});

export const client = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache(),
});
