exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-gatsby-theme-zoteapp-templates-index-js": () => import("./../../../src/gatsby-theme-zoteapp/templates/index.js" /* webpackChunkName: "component---src-gatsby-theme-zoteapp-templates-index-js" */),
  "component---src-gatsby-theme-zoteapp-templates-landing-page-js": () => import("./../../../src/gatsby-theme-zoteapp/templates/landing-page.js" /* webpackChunkName: "component---src-gatsby-theme-zoteapp-templates-landing-page-js" */),
  "component---src-gatsby-theme-zoteapp-templates-product-js": () => import("./../../../src/gatsby-theme-zoteapp/templates/product.js" /* webpackChunkName: "component---src-gatsby-theme-zoteapp-templates-product-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-orders-[id]-js": () => import("./../../../src/pages/orders/[id].js" /* webpackChunkName: "component---src-pages-orders-[id]-js" */),
  "component---src-pages-preview-product-js": () => import("./../../../src/pages/preview/product.js" /* webpackChunkName: "component---src-pages-preview-product-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank_you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---website-templates-03000-a-72-ad-3-f-46-f-3-950-b-a-095128982-c-8-node-modules-gatsby-theme-zoteapp-src-pages-about-js": () => import("./../../../../../website_templates/03000a72-ad3f-46f3-950b-a095128982c8/node_modules/gatsby-theme-zoteapp/src/pages/about.js" /* webpackChunkName: "component---website-templates-03000-a-72-ad-3-f-46-f-3-950-b-a-095128982-c-8-node-modules-gatsby-theme-zoteapp-src-pages-about-js" */),
  "component---website-templates-03000-a-72-ad-3-f-46-f-3-950-b-a-095128982-c-8-node-modules-gatsby-theme-zoteapp-src-pages-preview-product-[id]-js": () => import("./../../../../../website_templates/03000a72-ad3f-46f3-950b-a095128982c8/node_modules/gatsby-theme-zoteapp/src/pages/preview/product/[id].js" /* webpackChunkName: "component---website-templates-03000-a-72-ad-3-f-46-f-3-950-b-a-095128982-c-8-node-modules-gatsby-theme-zoteapp-src-pages-preview-product-[id]-js" */),
  "component---website-templates-03000-a-72-ad-3-f-46-f-3-950-b-a-095128982-c-8-node-modules-gatsby-theme-zoteapp-src-pages-test-js": () => import("./../../../../../website_templates/03000a72-ad3f-46f3-950b-a095128982c8/node_modules/gatsby-theme-zoteapp/src/pages/test.js" /* webpackChunkName: "component---website-templates-03000-a-72-ad-3-f-46-f-3-950-b-a-095128982-c-8-node-modules-gatsby-theme-zoteapp-src-pages-test-js" */),
  "component---website-templates-03000-a-72-ad-3-f-46-f-3-950-b-a-095128982-c-8-node-modules-gatsby-theme-zoteapp-src-pages-vendors-index-js": () => import("./../../../../../website_templates/03000a72-ad3f-46f3-950b-a095128982c8/node_modules/gatsby-theme-zoteapp/src/pages/vendors/index.js" /* webpackChunkName: "component---website-templates-03000-a-72-ad-3-f-46-f-3-950-b-a-095128982-c-8-node-modules-gatsby-theme-zoteapp-src-pages-vendors-index-js" */)
}

