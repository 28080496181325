import React, { useContext, useState, createContext } from "react";

const CartContext = createContext();

export const useCartContext = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [showCart, setShowCart] = useState(false);
  const [cartItems, setCartItems] = useState(0);

  const toggleCart = (value) => {
    setShowCart(value);
  };

  const setCartItemsCount = (count) => {
    setCartItems(count);
  }

  return (
    <CartContext.Provider value={{
      showCart,
      toggleCart,
      setCartItemsCount,
      cartItems
    }}>
      {children}
    </CartContext.Provider>
  );
};