import React, { useContext, useState, createContext, useEffect, useCallback } from "react";
import allProducts from '../../static/products.json'

const ProductsContext = createContext();

export const useProductsContext = () => useContext(ProductsContext);

export const ProductsProvider = ({ children }) => {
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = useCallback(() => setShowFilters(!showFilters), [showFilters]);
  return (
    <ProductsContext.Provider
      value={{
        showFilters,
        toggleFilters,
      }}>
      {children}
    </ProductsContext.Provider>
  );
};