import React, { useEffect, useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import { FaFacebook, FaInstagram, FaPinterest, FaTwitter, FaYoutube } from 'react-icons/fa'; // Make sure to install react-icons package
import { Link } from 'gatsby';
import CartOrCheckoutLink from "./CartOrCheckoutLink";
import FooterFab from "./Fab";


//create a method to fetch corresponding icon depending on the platform name
const getIcon = (platformName) => {
  switch (platformName) {
    case 'facebook':
      return <FaFacebook size={30} color='white' />
    case 'twitter':
      return <FaTwitter size={30} color='white' />
    case 'instagram':
      return <FaInstagram size={30} color='white' />
    case 'pinterest':
      return <FaPinterest size={30} color='white' />
    case 'youtube':
      return <FaYoutube size={30} color='white' />
    default:
      return null
  }
}



const Footer = (props) => {

  const {
    deliveryTerms = "",
    support = "",
    aboutUs = "",
    paymentTerms = "",
    physicalAddress = "",
    socialMedia = [],
    shopName = ""
  } = props.data.Zote

  const sanitizedDeliveryTerms = deliveryTerms;
  const sanitizedSupport = support;
  const sanitizedAboutUs = aboutUs;
  const sanitizedPaymentTerms = paymentTerms;
  const sanitizedPhysicalAddress = physicalAddress;
  const year = new Date().getFullYear()
  const cartItemCount = 5; // Assume this dynamically counts items in the cart
  //Get Phone number from env variable
  const [phone, setPhone] = useState(null);
  const [whatsapp, setWhatsapp] = useState(null);
  const [isOpen, setIsOpen] = useState(false);




  useEffect(() => {
    const phoneNumber = process.env.GATSBY_PHONE || null;
    const whatsappNumber = process.env.GATSBY_WHATSAPP || null;
    setPhone(phoneNumber);
    setWhatsapp(whatsappNumber);
  }, [])

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/${whatsapp}`, '_blank');
  };

  const handleCallClick = () => {
    window.open(`tel:${phone}`, '_self');
  };


  return (
    <>
      <div className="bg-accentRed text-white ">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 text-center md:text-left pt-10" >
          <div className="flex flex-col items-center md:items-start">
            <div dangerouslySetInnerHTML={{ __html: sanitizedDeliveryTerms }} />
          </div>
          <div className="flex flex-col items-center md:items-start">
            <div dangerouslySetInnerHTML={{ __html: sanitizedSupport }} />
          </div>
          <div className="flex flex-col items-center md:items-start">
            <div dangerouslySetInnerHTML={{ __html: sanitizedPaymentTerms }} />

          </div>
          <div className="flex flex-col items-center md:items-start font-custom">
            <div dangerouslySetInnerHTML={{ __html: sanitizedPhysicalAddress }} />

          </div>
        </div>
      </div>

      <footer className="bg-gray-800 text-white">
        <div className="container mx-auto px-6 py-4">
          <div className="flex flex-col sm:flex-row justify-between p-1">
            <div className="flex flex-1">
              <div dangerouslySetInnerHTML={{ __html: sanitizedAboutUs }} />
            </div>
            <div className="flex flex-row ">
              {socialMedia.map((item, index) => {
                if (!item.handle) return null
                return (
                  <a key={index} href={`${item.url}/${item.handle}`} className="mr-4">
                    {getIcon(item.platformName)}
                  </a>
                )
              })}
            </div>
          </div>

          <div className="flex items-start border-t border-gray-700 mt-4 pt-4">
            <p className="text-sm text-center text-gray-400">&copy; {year} {shopName}</p>
          </div>
        </div>


        <FooterFab />

      </footer>

      <CartOrCheckoutLink {...props} />
    </>
  )
}


export default function MyHeader(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          Zote {
            deliveryTerms,
            aboutUs
            support 
            paymentTerms
            physicalAddress
            shopName
            socialMedia{
              url
              name
              platformName
              handle
            }
          }
        }
      `}
      render={data => {
        return <Footer data={data} {...props} />
      }}
    />
  )
}