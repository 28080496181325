import React, { useEffect, useState } from "react"
import { FaPlus } from 'react-icons/fa'; // Make sure to install react-icons package
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { FaWhatsapp, FaPhone } from 'react-icons/fa';



const FooterFab = (props) => {


  //Get Phone number from env variable
  const [phone, setPhone] = useState(null);
  const [whatsapp, setWhatsapp] = useState(null);
  const [isBrowser, setIsBrowser] = useState(false);




  useEffect(() => {
    const phoneNumber = process.env.GATSBY_PHONE || null;
    const whatsappNumber = process.env.GATSBY_WHATSAPP || null;
    setPhone(phoneNumber);
    setWhatsapp(whatsappNumber);
    setIsBrowser(true);
  }, [])

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/${whatsapp}`, '_blank');
  };

  const handleCallClick = () => {
    window.open(`tel:${phone}`, '_self');
  };

  if (!isBrowser) return null;


  return (
    <Fab
      icon={<FaPlus />}
      mainButtonStyles={{
        backgroundColor: '#F15A22',
        color: 'white',
        width: '60px',
        height: '60px',
        borderRadius: '50%',
      }}
      style={{
        bottom: '20px',
        right: '20px',
        position: 'fixed',
        display: phone || whatsapp ? 'block' : 'none',
      }}
      alwaysShowTitle={true}
    >
      {whatsapp && (
        <Action
          text="Chat on WhatsApp"
          style={{
            backgroundColor: '#25D366',
            color: 'white',
            borderRadius: '50%',
          }}
          onClick={handleWhatsAppClick}
        >
          <FaWhatsapp size={30} />
        </Action>
      )}
      {phone && (
        <Action
          text="Call Us"
          style={{
            backgroundColor: '#34B7F1',
            color: 'white',
            borderRadius: '50%',
          }}
          onClick={handleCallClick}
        >
          <FaPhone size={30} />
        </Action>
      )}
    </Fab>
  )
}

export default FooterFab;
