import React from "react"
import { ApolloProvider } from "@apollo/client"
import { client } from "./client"
import { CartProvider } from "./cartProvider"
import { ProductsProvider } from "./productsProvider"
import { ChakraProvider, theme } from "@chakra-ui/react"
import Layout from "../components/layout"
import { DataFetchProvider } from "@zoteapp/data-fetch"
import axiosInstance from './axiosInstance';

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <ChakraProvider>
        <ProductsProvider>
          <CartProvider>
            <DataFetchProvider axios={axiosInstance}>
              {element}
            </DataFetchProvider>
          </CartProvider>
        </ProductsProvider>
      </ChakraProvider>
    </ApolloProvider>
  )
}
