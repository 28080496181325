import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: `/`,
});


axiosInstance.interceptors.request.use(
  async (config) => {

    config.headers['Content-Type'] = 'application/json';
    config.headers['Accept'] = 'application/json';
    config.headers['Authorization'] = `${process.env.GATSBY_BACKEND_TOKEN}`;
    // You can add other headers as needed
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);



export default axiosInstance;