import React from "react"
import { Link, navigate } from 'gatsby';
import { FaShoppingCart } from 'react-icons/fa';
import useCookie from "gatsby-theme-zoteapp/src/utils/useCookie";
import { useLocation } from "@reach/router"

const CartOrCheckoutLink = (props) => {
  const location = useLocation();
  const isCartPage = location.pathname === '/cart/' || location.pathname === '/cart';
  const cartItemCount = useCookie('zote_cart_count');
  console.log("LOCATION", location.pathname);

  if (!cartItemCount || cartItemCount === 0) {
    return null;
  }
  return (
    <>
      {isCartPage ? (
        // Render a checkout button when on the cart page
        <button
          className="fixed inset-x-0 bottom-0 z-50 px-4 py-3 bg-green-500 md:hidden flex justify-center items-center shadow-lg text-white hover:bg-green-700 transition-colors"
          aria-label="Proceed to checkout"
          onClick={() => {
            // Implement checkout logic here or navigate to checkout page
            navigate('/checkout');
          }}
        >
          Proceed to Checkout
        </button>
      ) : (
        // Render the cart link when not on the cart page
        <Link
          to="/cart"
          className="fixed inset-x-0 bottom-0 z-50 px-4 py-3 bg-primaryBlue md:hidden flex justify-between items-center shadow-lg text-white hover:bg-primaryDark transition-colors"
          aria-label="View your cart"
        >
          <span>Your Cart</span>
          <div className="flex items-center">
            {cartItemCount > 0 && (
              <span className="mr-2 text-sm font-semibold">{cartItemCount} Items</span>
            )}
            <FaShoppingCart size={24} />
          </div>
        </Link>
      )}
    </>
  );
};

export default CartOrCheckoutLink;
